<template>
  <div class="uk-container uk-text-left">
    <h1 class="uk-text-left">{{ $t('Pages.AddOrganization.Title') }}</h1>
    <hr />

    <div class="uk-container uk-container-small">
      <div class="uk-grid-divider" uk-grid>
        <div class="uk-width-expand@m">
          <div class="uk-container uk-margin-large-top">
            <form class="uk-form-horizontal uk-margin-large uk-text-left" @submit.prevent="createOrg();">

              <div class="uk-margin-small">
                <label class="uk-form-label" for="form-horizontal-text">
                  {{$t("Generic.Labels.Name")}}
                  <span class="uk-text-danger">*</span>
                </label>
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-1-1">
                    <input class="uk-input" :class="{'invalid-border': form.errors.name }" id="form-horizontal-text" type="text" :placeholder="$t('Generic.Labels.Name')" v-model.trim="form.org.name" @blur="validateName()" @focus="form.errors.name=''">
                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="form.errors.name" uk-icon="icon: warning"></span>
                    <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="form.errors.name" >{{this.form.errors.name}}</span>
                  </div>
                </div>
              </div>

              <div class="uk-margin-small">
                <label class="uk-form-label" for="form-horizontal-text">
                  {{$t("Generic.Labels.Address")}}
                  <span class="uk-text-danger">*</span>
                </label>
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-1-1">
                    <textarea :class="{'invalid-border': form.errors.address }" class="uk-textarea" id="form-horizontal-text" type="text" :placeholder="$t('Generic.Labels.Address')" v-model.trim="form.org.address" @blur="validateAddress()" @focus="form.errors.address=''"> </textarea>
                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="form.errors.address" uk-icon="icon: warning"></span>
                    <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="form.errors.address" >{{this.form.errors.address}}</span>
                  </div>
                </div>
              </div>

              <div class="uk-margin-small">
                <label class="uk-form-label" for="form-horizontal-text">{{$t('Generic.Labels.Phone')}}</label>
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-1-1">
                    <input class="uk-input" id="form-horizontal-text" :class="{'invalid-border': form.errors.phone }"  type="text" :placeholder="$t('Generic.Labels.Phone')" v-model.trim="form.org.phone" @blur="validatePhone()" @focus="form.errors.phone=''">
                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="form.errors.phone" uk-icon="icon: warning"></span>
                    <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="form.errors.phone" >{{this.form.errors.phone}}</span>
                  </div>
                </div>
              </div>
              <br />

              <div class="uk-magin-small" uk-height-match>
                <label class="uk-form-label custom-top-margin" for="form-horizontal-text">{{$t("Generic.Labels.Logo")}}</label>

                <div class="uk-form-controls" id="image-upload">
                  <InputUploader @start="uploading = true" @complete="onUploadComplete" :multiple="false" :accept="`image/*`" />
                </div>

              </div>

              <div class="uk-magin-small" uk-height-match>
                <label class="uk-form-label custom-top-margin" for="form-horizontal-text"></label>

                <div class="uk-form-controls uk-text-left">
                  <h5 class="uk-form-controls uk-text-muted uk-margin-remove uk-margin-small-left uk-text-left">{{$t('Generic.Labels.Image1x1')}}</h5>
                </div>

              </div>

              <hr />
              
            </form>
            <div class="uk-flex uk-flex-center">
                <router-link :to="{ name: 'Organizations'}">
                    <button class="uk-button uk-button-default uk-margin-small-right">{{$t('Actions.Cancel')}}</button>
                </router-link> 
                <button class="uk-button uk-button-primary uk-margin-small-left" @click.prevent="createOrg();">{{ $t('Actions.Create') }}</button>

            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import Notification from '@/components/util/Notification';
import InputUploader from '@/views/admin/mediaLibrary/InputUploader';


export default {
  name: 'OrganizationAdd',
  components: {
    InputUploader
  },
  data () {
    return {
      form: {
        org: {
          name: '',
          address: '',
          phone: ''
        },
        errors: {
          name: '',
          address: '',
          phone: ''
        }
      }
    }
  },
  computed: {
  },
  methods: {
    async createOrg () {
      this.validateName();
      this.validateAddress();
      this.validatePhone();
      for (const key in this.form.errors) {
        if (this.form.errors[key].length) {
          return false;
        }
      }
      const params = {
        name: this.form.org.name,
        phone: this.form.org.phone,
        address: this.form.org.address,
      }
      if (this.form.org.logo) {
        params.logo = {
          key: this.form.org.logo
        }
      }
      const response = await this.$store.dispatch('createOrg', params);
      if (response.id) {
        Notification.showNotification(this.$t('Pages.AddOrganization.OrgCreateSuccess'), this.$t('Pages.AddOrganization.OrgCreateSuccessDesc'));
        return this.$router.push({ name: 'Organizations'});
      }
    },
    validateName () {
      if (!this.form.org.name || this.form.org.name.length < 3) {
        this.form.errors.name = this.$t('Generic.Errors.Min3Chars');
      } else {
        this.form.errors.name = false;
      }
    },
    validateAddress () {
      if (!this.form.org.address || this.form.org.address.length < 3) {
        this.form.errors.address = this.$t('Generic.Errors.Min3Chars');
      } else {
        this.form.errors.address = false;
      }
    },
    validatePhone () {
      if (this.form.org.phone.length && this.form.org.phone.length < 10) {       
        this.form.errors.phone = this.$t('Generic.Errors.Min10Chars');
      } else {
        this.form.errors.phone = false;
      }
    },
    async onUploadComplete (files) {
      const file = files[files.length - 1];
      this.form.org.logo = file.path;
      // this.form.org.previewUrl = file.previewUrl
      this.$children[0].startedFiles = [file];
      this.$children[0].allFiles = this.$children[0].startedFiles;
    }
  }
}
</script>

<style scoped>
.custom-top-margin {
  margin-top: 34px;
}
.uk-button-default {
  color: var(--text-color);
  background: var(--app-shadow-color);
}
</style>